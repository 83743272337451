<script setup lang='ts'>
import { computed, onMounted } from "vue"
import { useHead } from "@unhead/vue"
import { Footer } from "./footer"
import { Header } from "./header"
import { Banner } from "./banner"
import { SectionSeoContent } from "widgets"
import { useRoute } from "vue-router"
import { ROUTE_NAMES } from "shared/constants"
import { useBreakpoints } from "@vueuse/core"
import { useCatalogDeviceStore, useSeoContentStore, useAbStore } from "stores"

const breakpoints = useBreakpoints({ md: 768 })
const mdAndLarger  = breakpoints.greaterOrEqual("md")

const route = useRoute()
const seoContentStore = useSeoContentStore()
const catalogDeviceStore = useCatalogDeviceStore()
const abStore = useAbStore()

const isTradeInPage = computed(() => route.name === ROUTE_NAMES.TRADE_IN)
const isCatalogDevicePage = computed(() => route.name === ROUTE_NAMES.CATALOG_DEVICE)
const isOneOfCartPage = computed(() => route.name === ROUTE_NAMES.CART || route.name === ROUTE_NAMES.THANKS)
const isOneOfSubscriptionPage = computed(() => route.name === ROUTE_NAMES.SUBSCRIPTION || route.name === ROUTE_NAMES.SUBSCRIPTION_FORM ||
  route.name === ROUTE_NAMES.SUBSCRIPTION_FORM_FILLED || route.name === ROUTE_NAMES.SUBSCRIPTION_OUT_OF_STOCK || route.name === ROUTE_NAMES.SUBSCRIPTION_THANKS ||
  route.name === ROUTE_NAMES.SUBSCRIPTION_DESCRIPTION )

const meta = computed(() => {
  const result: {
    title?: string,
    meta?: [{ name: string; content: string }]
  } = {
    title: "Смартфоны по выгодным ценам | Pedant.market"
  }

  if (Array.isArray(seoContentStore.list) && seoContentStore.list.length) {
    if (seoContentStore.list[0].Title) {
      result.title = seoContentStore.list[0].Title
    }

    if (seoContentStore.list[0].MetaDescription) {
      result.meta = [
        {
          name: "description",
          content: seoContentStore.list[0].MetaDescription
        }
      ]
    }
  }

  if (isCatalogDevicePage.value && catalogDeviceStore.item) {
    result.title = `${catalogDeviceStore.item.FullName} | Выгодные цены в маркетплейсе Pedant.Market`
  }

  return result
})

useHead(meta)

const isShowBanner = computed(() => !(isTradeInPage.value || isOneOfCartPage.value || isOneOfSubscriptionPage.value))

onMounted(() => abStore.initAllExperiments())
</script>

<template>
  <Banner v-show="isShowBanner" />
  <Header />

  <main>
    <slot />
    <SectionSeoContent />
  </main>

  <Footer v-if="!(isTradeInPage && mdAndLarger)" />
</template>
