import { createApp } from "vue"
import {router, store} from "./providers"
import { log, initYandexMetrika } from "shared/lib"
import App from "./index.vue"

import { createVfm } from "vue-final-modal"
import "vue-final-modal/style.css"

import VueLazyload from "vue-lazyload"

/*--------- VUE YANDEX MAPS START ---------*/
import { createYmaps } from "vue-yandex-maps"
import type { VueYandexMaps } from "vue-yandex-maps"

const settings: VueYandexMaps.PluginSettings = {
  apikey: import.meta.env.APP_YANDEX_MAP_API_KEY,
  lang: "ru_RU"
}
/*--------- VUE YANDEX MAPS END ---------*/

/*--------- VUE UNHEAD START ---------*/
import { createHead } from "@unhead/vue"

const head = createHead()
/*--------- VUE UNHEAD END ---------*/

const app = createApp(App)
  .use(router)
  .use(store)
  .use(head)
  .use(createYmaps(settings))
  .use(createVfm())
  .use(VueLazyload)

initYandexMetrika(app, router)

app.config.errorHandler = function (message, component, info) {
  log({ component, level: "Error", info, message })
}

app.config.warnHandler = function (message, component, info) {
  log({ component, level: "Warning", info, message })
}

export default app
